import { useTheme } from 'utils/theme-context';
import { Themes } from 'constant';

import FooterTheme1 from './Footer.theme1';
import FooterTheme2 from './Footer.theme2';
import FooterTheme3 from './Footer.theme3';

export default function Sections(props) {
  const { theme } = useTheme();
  return theme === Themes.THEME_1 ? (
    <FooterTheme1 {...props} />
  ) : theme === Themes.THEME_2 ? (
    <FooterTheme2 {...props} />
  ) : (
    <FooterTheme3 {...props} />
  );
}
