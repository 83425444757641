import Link from 'next/link';
import Image from './Image';
import styles from './Footer.theme2.module.css';
import PropTypes from 'prop-types';
import { FooterLinks } from 'constant';
import { useTextFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';
import { useMemo } from 'react';

const propTypes = {
  logo: PropTypes.object,
  brandName: PropTypes.string.isRequired,
  recentYear: PropTypes.number,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  linkHoverColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
  footerLinks: PropTypes.shape({
    privacyPolicy: PropTypes.string,
    termsAndConditions: PropTypes.string,
    refundPolicy: PropTypes.string,
    contactUs: PropTypes.string,
  }),
  externalPrivacyPolicyLink: PropTypes.string,
  externalTermsAndConditionsLink: PropTypes.string,
  externalRefundsLink: PropTypes.string,
  externalContactUsLink: PropTypes.string,
};

const defaultProps = {
  logo: null,
  recentYear: new Date().getFullYear(),
  textColor: null,
  backgroundColor: null,
  linkHoverColor: null,
  textFont: null,
  footerLinks: {
    privacyPolicy: FooterLinks.PRIVACY_POLICY,
    termsAndConditions: FooterLinks.TERMS_AND_CONDITIONS,
    refundPolicy: FooterLinks.REFUND_POLICY,
    contactUs: FooterLinks.CONTACT_US,
  },
  externalPrivacyPolicyLink: null,
  externalTermsAndConditionsLink: null,
  externalRefundsLink: null,
  externalContactUsLink: null,
};

export default function Footer({
  logo,
  brandName,
  recentYear,
  textColor,
  backgroundColor,
  linkHoverColor,
  textFont,
  footerLinks,
  externalPrivacyPolicyLink,
  externalRefundsLink,
  externalTermsAndConditionsLink,
  externalContactUsLink,
}) {
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  const privacyPolicyLink = useMemo(() => {
    return externalPrivacyPolicyLink ?? footerLinks.privacyPolicy;
  }, [externalPrivacyPolicyLink, footerLinks.privacyPolicy]);

  const termsAndConditionsLink = useMemo(() => {
    return externalTermsAndConditionsLink ?? footerLinks.termsAndConditions;
  }, [externalTermsAndConditionsLink, footerLinks.termsAndConditions]);

  const refundsLink = useMemo(() => {
    return externalRefundsLink ?? footerLinks.refundPolicy;
  }, [externalRefundsLink, footerLinks.refundPolicy]);

  const contactUsLink = useMemo(() => {
    return externalContactUsLink ?? footerLinks.contactUs;
  }, [externalContactUsLink, footerLinks.contactUs]);

  return (
    <footer className={`root ${styles.footer}`} id="footer">
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--secondary-color)')}
          ${buildCssVar('--link-hover-color', linkHoverColor, textColor, 'var(--text-color-dark)')}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      {logo && (
        <div className={styles.footer_logo}>
          <Image src={logo} alt={`${brandName} Logo`} placeholder="blur" />
        </div>
      )}
      <div className={styles.links}>
        <Link href={privacyPolicyLink} legacyBehavior>
          <a target="_blank" className={styles.link}>
            Privacy Policy
          </a>
        </Link>
        <Link href={termsAndConditionsLink} legacyBehavior>
          <a target="_blank" className={styles.link}>
            Terms & Conditions
          </a>
        </Link>
        <Link href={refundsLink} legacyBehavior>
          <a target="_blank" className={styles.link}>
            Returns & Refunds
          </a>
        </Link>
        <Link href={contactUsLink} legacyBehavior>
          <a target="_blank" className={styles.link}>
            Contact Us
          </a>
        </Link>
      </div>
      <p className={styles.copyright}>
        {brandName} © {recentYear} — All Rights reserved.
      </p>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;
