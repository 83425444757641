import { FunnelPageType } from 'constant/funnel';

export function mapArticlesByDirectusPageId(directusArticles, adminArticles) {
  if (!directusArticles) return [];

  const filteredArticles = directusArticles.reduce((accumulator, article) => {
    const matchingArticle = adminArticles?.find(a => a.directusPageId === article.id);

    if (matchingArticle) {
      const linkedPages = [].concat(
        matchingArticle.articleProductPages,
        matchingArticle.externalProductPages,
        matchingArticle.salesProductPages
      );

      const updatedArticle = {
        ...article,
        path: matchingArticle.path,
        linkedPages,
      };

      accumulator.push(updatedArticle);
    }

    return accumulator;
  }, []);

  return filteredArticles;
}

export function getUniqueProductCardIds(articles) {
  const uniqueProductCards = new Map();

  articles.forEach(article => {
    if (article.linkedPages) {
      article.linkedPages.forEach(linkedPage => {
        const { productCardId } = linkedPage;
        const pageId = linkedPage.page.id;

        if (!uniqueProductCards.has(pageId)) {
          uniqueProductCards.set(pageId, productCardId);
        }
      });
    }
  });

  return Array.from(uniqueProductCards.values());
}

export const combineSalesPagesAndDirectusPages = (articles, directusPages) => {
  const combinedPages = articles.reduce((acc, article) => {
    if (article.linkedPages) {
      article.linkedPages.forEach(linkedPage => {
        if (linkedPage.page?.type === FunnelPageType.SALES_PAGE) {
          const matchingDirectusPage = directusPages?.find(
            directusPage => directusPage?.id == linkedPage?.productCardId
          );

          if (matchingDirectusPage) {
            const combinedPage = {
              ...linkedPage,
              ...matchingDirectusPage,
              tag: article.tag,
            };
            acc.push(combinedPage);
          }
        }
      });
    }

    return acc;
  }, []);
  return combinedPages;
};

export function kebabToTitleCase(str) {
  if (!str) {
    return '';
  }

  const words = str.split('-');
  return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function getUniqueTags(pages) {
  if (pages) {
    const tags = pages.map(page => page.tag?.name);
    const uniqueTags = [...new Set(tags)];
    return uniqueTags;
  }
}

export const formatSlug = slug => {
  return slug?.toLowerCase().replace(/[^a-z0-9&]+/g, '-');
};
