import Link from 'next/link';
import Image from './Image';
import styles from './Footer.theme1.module.css';
import PropTypes from 'prop-types';
import { FooterLinks } from 'constant';
import { useTextFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

const propTypes = {
  logo: PropTypes.object,
  brandName: PropTypes.string.isRequired,
  recentYear: PropTypes.number,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
  footerLinks: PropTypes.shape({
    privacyPolicy: PropTypes.string,
    termsAndConditions: PropTypes.string,
    refundPolicy: PropTypes.string,
    contactUs: PropTypes.string,
  }),
};

const defaultProps = {
  logo: null,
  recentYear: new Date().getFullYear(),
  textColor: null,
  backgroundColor: null,
  textFont: null,
  footerLinks: {
    privacyPolicy: FooterLinks.PRIVACY_POLICY,
    termsAndConditions: FooterLinks.TERMS_AND_CONDITIONS,
    refundPolicy: FooterLinks.REFUND_POLICY,
    contactUs: FooterLinks.CONTACT_US,
  },
};

export default function Footer({
  logo,
  brandName,
  recentYear,
  textColor,
  backgroundColor,
  textFont,
  footerLinks,
}) {
  const textFontOverrides = useTextFontOverrides(textFont?.family);
  return (
    <footer className={`root ${styles.footer}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--secondary-color)')}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      {logo && (
        <div className={styles.footer_logo}>
          <Image src={logo} alt={`${brandName} Logo`} placeholder="blur" />
        </div>
      )}
      <Link href={footerLinks.privacyPolicy} legacyBehavior>
        <a target="_blank" className={styles.link}>
          Privacy Policy
        </a>
      </Link>
      <Link href={footerLinks.termsAndConditions} legacyBehavior>
        <a target="_blank" className={styles.link}>
          Terms & Conditions
        </a>
      </Link>
      <Link href={footerLinks.refundPolicy} legacyBehavior>
        <a target="_blank" className={styles.link}>
          Returns & Refunds
        </a>
      </Link>
      <Link href={footerLinks.contactUs} legacyBehavior>
        <a target="_blank" className={styles.link}>
          Contact Us
        </a>
      </Link>
      <p className={styles.copyright}>
        © {recentYear} {brandName} — All Rights Reserved
      </p>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;
