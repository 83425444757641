import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { FooterLinks } from 'constant/footer-links';
import { useTextFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';
import Image from './Image';
import Link from 'next/dist/client/link';
import NavigationLink from './NavigationLink';

import styles from './Footer.theme3.module.css';
import customSubscriptionStyles from './FooterSubscriptionForm.module.css';

const SubscriptionForm = dynamic(() => import('./SubscriptionForm'), { ssr: false });

const propTypes = {
  logo: PropTypes.object,
  brandName: PropTypes.string.isRequired,
  recentYear: PropTypes.number,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
  footerLinks: PropTypes.shape({
    privacyPolicy: PropTypes.string,
    termsAndConditions: PropTypes.string,
    refundPolicy: PropTypes.string,
    contactUs: PropTypes.string,
  }),
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        icon: PropTypes.object,
        name: PropTypes.string,
        url: PropTypes.string,
      }),
    })
  ),
  navigationLinks: PropTypes.array,
  subscription: PropTypes.object,
};

const defaultProps = {
  logo: null,
  recentYear: new Date().getFullYear(),
  textColor: null,
  backgroundColor: null,
  textFont: null,
  footerLinks: {
    privacyPolicy: FooterLinks.PRIVACY_POLICY,
    termsAndConditions: FooterLinks.TERMS_AND_CONDITIONS,
    refundPolicy: FooterLinks.REFUND_POLICY,
    contactUs: FooterLinks.CONTACT_US,
  },
  socialLinks: null,
  navigationLinks: null,
  subscription: null,
};

function Footer({
  logo,
  brandName,
  recentYear,
  textColor,
  backgroundColor,
  textFont,
  footerLinks,
  socialLinks,
  navigationLinks,
  subscription,
}) {
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', textColor, 'var(--text-color-light)')}
          ${buildCssVar('--background-color', backgroundColor, '#121212')}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div className={styles.wrapper}>
        {logo && (
          <div className={styles.mobile_logo}>
            <div className={styles.logo_wrapper}>
              <Image src={logo} alt="Logo" />
            </div>
          </div>
        )}
        <div className={styles.pages_form_wrapper}>
          <SubscriptionForm {...subscription} styles={customSubscriptionStyles} />
          <div className={styles.pages_links}>
            {!!navigationLinks && (
              <div>
                <p>Explore</p>
                <div className={styles.links}>
                  {navigationLinks.map(({ item }) => (
                    <NavigationLink key={item.name} slug={item.name} />
                  ))}
                </div>
              </div>
            )}
            <div>
              <p>More</p>
              <div className={styles.links}>
                <NavigationLink slug="products" displayText="Shop" />
              </div>
              <div className={styles.links}>
                <Link href={footerLinks.contactUs} legacyBehavior>
                  <a target="_blank" className={styles.link}>
                    Contact Us
                  </a>
                </Link>
                <Link href={footerLinks.ourStory} legacyBehavior>
                  <a target="_blank" className={styles.link}>
                    Our Story
                  </a>
                </Link>
              </div>
            </div>
            {!!socialLinks && (
              <div className={styles.desktop_social}>
                <p>Social</p>
                <div className={styles.links}>
                  {socialLinks.map(({ item }) => (
                    <Link href={item.url} key={item.url} legacyBehavior>
                      <a target="_blank" rel="noreferrer">
                        {item.name}
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={`${styles.logo_links_wrapper} ${!logo ? styles.right_aligned : ''}`}>
          {logo && (
            <div className={styles.desktop_logo}>
              <div className={styles.logo_wrapper}>
                <Image src={logo} alt="Logo" />
              </div>
            </div>
          )}

          <div className={styles.links_wrapper}>
            <p className={styles.copyright}>
              © {recentYear} {brandName} — All Rights Reserved
            </p>
            <Link href={footerLinks.privacyPolicy} legacyBehavior>
              <a target="_blank" className={styles.link}>
                Privacy Policy
              </a>
            </Link>
            <Link href={footerLinks.termsAndConditions} legacyBehavior>
              <a target="_blank" className={styles.link}>
                Terms & Conditions
              </a>
            </Link>
            <Link href={footerLinks.refundPolicy} legacyBehavior>
              <a target="_blank" className={styles.link}>
                Returns & Refunds
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
