import PropTypes from 'prop-types';

import { useGetPathWithQueryParams } from 'utils/article-link';
import { formatSlug } from 'utils/article-helpers';
import Link from 'next/link';

const propTypes = {
  slug: PropTypes.string.isRequired,
  path: PropTypes.string,
  onCloseDropdowns: PropTypes.func,
  displayText: PropTypes.string,
};

const defaultProps = {
  path: null,
  onCloseDropdowns: null,
  displayText: null,
};

function NavigationLink({ slug, path, onCloseDropdowns, displayText }) {
  const handleClick = async () => {
    onCloseDropdowns?.();
  };

  const link = useGetPathWithQueryParams(
    `/category/${formatSlug(slug)}/${path ? formatSlug(path) : ''}`
  );
  return (
    <>
      <Link href={link}>
        <button onClick={handleClick}>{displayText || path || slug}</button>
      </Link>
    </>
  );
}

NavigationLink.propTypes = propTypes;
NavigationLink.defaultProps = defaultProps;

export default NavigationLink;
